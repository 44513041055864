import type { PostHog } from 'posthog-js/react';
import { useToast } from '@leafwell/components';
import { usePostHog } from 'posthog-js/react';
// import { useRollbar } from '@rollbar/react';

const API_ENDPOINT = `${process.env.NEXT_PUBLIC_MEDICAL_CARD_URL}/api/forms/registry`;
const GENERIC_ERROR = 'Something went wrong. Try again by refreshing the page.';

export type SignupData = {
  email: string;
  state_code: string;
  uuid?: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
  email_friend?: string;
  discount_code?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

// Alias the MC user id with ours - https://posthog.com/docs/product-analytics/identify#alias-assigning-multiple-distinct-ids-to-the-same-user
function aliasPosthogUser(uuid: string, posthog: PostHog) {
  const distinctID = posthog.get_distinct_id();
  posthog.alias(uuid, distinctID);
}

async function signup({ method, params }) {
  const res = await fetch(API_ENDPOINT, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Client-Type': 'leafwell-website',
    },
    body: JSON.stringify(params),
  });

  if (!res) {
    return { error: GENERIC_ERROR };
  }

  return await res.json();
}

export function useMedicalCardSignup() {
  // const rollbar = useRollbar();
  const { addToast } = useToast();
  const posthog = usePostHog();

  async function createSignup(params: SignupData) {
    const res = await signup({ method: 'POST', params });

    if (res.error) {
      if (!res.errors) {
        addToast({
          content: GENERIC_ERROR,
          key: 'mc-signup-create',
          type: 'negative',
          shouldRemove: true,
        });
      }

      return res;
    }

    aliasPosthogUser(res.patient_registry.uuid, posthog);

    return { data: res.patient_registry };
  }

  async function updateSignup(
    params: SignupData & { accepted_terms_of_service: boolean },
  ) {
    const res = await signup({ method: 'PUT', params });

    if (res.error) {
      if (!res.errors) {
        addToast({
          content: GENERIC_ERROR,
          key: 'mc-signup-update',
          type: 'negative',
          shouldRemove: true,
        });
      }

      return res;
    }

    aliasPosthogUser(params.uuid, posthog);

    return res;
  }

  return { createSignup, updateSignup };
}
